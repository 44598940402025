import { IOrderOption } from "../Model/IOrderOption";

export class OptionsRepository{
    // private options:IOrderOption[] = [
    //     {
    //         id:"1",
    //         name:"Strona z podstawowymi informacjami i Waszym zdjęciem w tle z adresem <WaszWybranyAdres>.naszslubonline.pl",
    //         price: 200,
    //         optional: false
    //     },
    //     {
    //         id:"11",
    //         name:"Możliwość zabezpieczenia dostępu hasłem",
    //         price: 0,
    //         optional: true
    //     },
    //     {
    //         id:"2",
    //         name:"Własna domena na rok <WaszWybranyAdres>.pl",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"3",
    //         name:"Własny adres E-mail",
    //         price: 200,
    //         optional: true,
    //         availableOnlyWithIds:["2"]
    //     },
    //     {
    //         id:"4",
    //         name:"Historia Waszej miłości",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"5",
    //         name:"Informacje o świadkach",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"12",
    //         name:"Licznik do/od ślubu",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"6",
    //         name:"Szablon do wyboru",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"7",
    //         name:"Księga gości",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"8",
    //         name:"Możliwość tworzenia postów do kontaktu z goścmi",
    //         price: 555,
    //         optional: true
    //     },
    //     {
    //         id:"9",
    //         name:"Indywidualne dopasowanie strony",
    //         price: 200,
    //         optional: true
    //     },
    //     {
    //         id:"10",
    //         name:"Galeria zdjęć",
    //         choiceOptions:[
    //             {
    //                 id:"10-1",
    //                 name:"5 GB",
    //                 price: 10,
    //                 optional: false
    //             },
    //             {
    //                 id:"10-2",
    //                 name:"25 GB",
    //                 price: 20,
    //                 optional: false
    //             },
    //             {
    //                 id:"10-3",
    //                 name:"50 GB",
    //                 price: 30,
    //                 optional: false
    //             }
    //         ],
    //         price: 0,
    //         optional: true
    //     }
    // ];

    public getAllOptions():IOrderOption[]{
        return (window as any)["order_options"];
        //return [...this.options];
    }
}