import React from "react";

export interface SelectionButtonProps{
    id:string,
    buttonClicked?:(key:string)=>void,
    text?:string,
    icon?:string,
    disabled?:boolean
}

export class SelectionButton extends React.Component<SelectionButtonProps>{
    
    constructor(props:SelectionButtonProps){
        super(props);
        this.buttonClicked = this.buttonClicked.bind(this);
    }

    render(){
        return (
            <div style={{width:'97%', textAlign:'center', opacity:this.props.disabled ? '0.5' : '1'}} className="button button-rounded button-naszastronaslubna" onClick={this.buttonClicked as any}>
                {this.props.text && this.props.text}
                {this.props.icon && <i className={this.props.icon}></i>}
            </div>
        );
    }

    buttonClicked(){
        !this.props.disabled && this.props.buttonClicked && this.props.buttonClicked(this.props.id);
    }
}