import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { OptionsDisplay } from './Components/OptionsDisplay';
import { OptionsRepository } from './Repositories/OptionsRepository';
import { Contact } from './Components/ContactForm/Contact';
import { IOrderOption } from './Model/IOrderOption';

function App(props:any) {
  const [viewData, setViewData] = useState({
    contactFormVisible:false,
    selectedOptions:([] as IOrderOption[])
  });
  return (
    <div className="App">
      {viewData.contactFormVisible ? null : <OptionsDisplay onNextClicked={(options:IOrderOption[])=>{setViewData({contactFormVisible:true, selectedOptions:options})}} options={new OptionsRepository().getAllOptions()}/>}
      {viewData.contactFormVisible ? <Contact selectedOptions={viewData.selectedOptions} onBack={()=>{setViewData({contactFormVisible:false, selectedOptions:[]})}}/> : null}
    </div>
  );
}

export default App;
