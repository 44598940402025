import React from "react";


function SumUp(props:any){
    let options = props.selectedOptions;
    
    const sumForOptions = ()=>{
        let sum = 0;
        for(let i=0; i<options.length;i++){
            sum +=options[i].price;
        }
        return sum;
    }

    return (
        <div>
            <ul className="list-group">
                <li className="list-group-item centerAlign">Podsumowanie</li>
                {options.map((o:any)=>{
                    return (
                        <li className="list-group-item">
                            <i style={{paddingRight:"10px"}} className="icon-ok accentColor"></i>
                            {o.name}
                        </li>
                    );
                })}
                <li className="list-group-item">
                    Łącznie: {sumForOptions()} PLN
                </li>
            </ul>
        </div>
    )


}

export default SumUp;