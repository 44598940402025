import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


const render = ()=>{
  var targetElement = document.getElementById('order-configuration');
  var navigateTo = targetElement?.dataset.navigate;
  ReactDOM.render(
    <React.StrictMode>
      <App navigateTo={navigateTo}/>
    </React.StrictMode>,
    document.getElementById('order-configuration')
  );
}

const initialize = ()=> {
  if((window as any).grecaptcha){
    render();
  }
  else{
    window['afterRecaptchaLoad'] = ()=>{
      //It is so fuckin' ugly... No idea why the component is not loading correctly (see if it works in WP after adding wp_enqueue_script.)
      //Currently we are not working in WP, local debug
      render();
    };
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = 'https://www.google.com/recaptcha/api.js?onload=afterRecaptchaLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

initialize();