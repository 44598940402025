import { Field, Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { ReCAPTCHA } from "react-google-recaptcha";
import { IOrderOption } from "../../Model/IOrderOption";
import { IContactFormResponse } from "./IContactFormResponse";
import SumUp from "./SumUp";

export interface ContactProps{
    selectedOptions:IOrderOption[],
    onBack:()=>void
}

export const Contact : FC<ContactProps> = (props)=>{
    const [verified, setVerified] = useState(false);
    const [status, setStatus] = useState<FormState>(FormState.NewMail);
    const recaptchaRef = React.createRef<ReCAPTCHA>();
    const captchaValueChanged = (value:any)=>{
        if(value){
            setVerified(true);
        }
    };
    const recaptchaVerified = ()=>{
        if(verified){
            return true;
        }
        if(recaptchaRef.current && recaptchaRef.current.getValue() !== ''){
            return true;
        }
        return false;
    };
    const captchaExpired = ()=>{
        setVerified(false);
    }
    const validation = ({name, message, email})=>{
        let errors = {} as any;
        if(!name || name == ''){
            errors.name = 'Fajnie by było móc zwracać się do Was inaczej niż "Państwo X".';
        }
        if(!email || email ==''){
            errors.email = 'Nie uda się nam z Wami skontaktować, jeśli nie podacie adresu Email';
        }
        else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
            errors.email = 'Nie uda się nam z Wami skontaktować jeśli nie podacie poprawnego adresu Email';
        }
        return errors; 
    };
    const asyncSubmit = async (values:any)=>{
        let formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('input', values.message);
        formData.append('selectedOptions', props.selectedOptions ? JSON.stringify(props.selectedOptions) : 'No options selected');
        formData.append('grecaptcha', recaptchaRef.current?.getValue() as string || '');
        let response = await fetch("/wp-content/plugins/order-form/frontend/build/postform.php",
        {
            body: formData,
            method: "post"
        });
        try{
            let responseText = await response.text();
            let obj = JSON.parse(responseText) as IContactFormResponse;
            if(obj.success){
                setStatus(FormState.EmailSent);
            }
            else{
                setStatus(FormState.ErrorSendingEmail);
            }
        }
        catch{
            setStatus(FormState.ErrorSendingEmail);
        }
    };
  

    const renderForm = ()=>{
        return (
            <div>
                <div style={{height:'50px'}}>
                    <i style={{float:'left', cursor:'pointer', fontSize:'2em'}} className='icon-line-arrow-left-circle' onClick={props.onBack}/>
                </div>
                <SumUp selectedOptions={props.selectedOptions}/>
           
                    <Formik
                    initialValues={{
                        name: '',
                        message: '',
                        email: '',
                    }}
                    validate={validation}
                    onSubmit={asyncSubmit}
                    >
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                                <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="template-contactform-name">Jak się do Was zwracać? <small>*</small></label>
                                    <Field name="name" className="sm-form-control required" type="text" placeholder="" />
                                    {errors.name && touched.name && <div>{errors.name}</div>}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="email">Wasz adres Email <small>*</small></label>
                                    <Field name="email" className="sm-form-control required" type="email" placeholder="PaniIPanSzczesliwi@big.love"/>
                                    {errors.email && touched.email && <div>{errors.email}</div>}
                                </div>
                            </div>
                            <div className="w-100"></div>
                            <div className="row">
                                <div className="col-12 form-group">
                                    <label htmlFor="template-contactform-message">Chcecie dodać coś od siebie? <small>*</small></label>
                                    <Field component="textarea" cols={30} rows={6} name="message" className="sm-form-control required" placeholder="Dodatkowe życzenia?"/>
                                </div>
                            </div>
                            <div>
                                Kliknięcie "Wyślij" nie jest w żaden sposób wiążące. Otrzymamy od Was wiadomość Email z preferowaną konfiguracją i następnie skontaktujemy się w celu ustalenia szczegółów:)
                            </div>
                            <div style={{paddingTop:"25px"}}>
                                Wypełnienie formularza oznacza wyrażenie zgody na przetwarzanie przez nas, podanych w formularzu danych osobowych w celu udzielenia odpowiedzi na zadane zapytanie i w zależności od jego treści przedstawienia oferty.
                                Kim jesteśmy i jak wycofać zgodnę na przetwarzanie danych? <a href="/prywatnosc" target="_blank">Zobaczcie tutaj!</a>
                            </div>
                            {recaptchaVerified() ?
                                (<button type="submit" disabled={isSubmitting} className="button button-3d m-0">
                                    {isSubmitting ? "Wysyłam..." : "Wyślij"}
                                </button>)
                                :
                                null
                            }
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LcKtvYdAAAAAKuMt9d7DBBqXODmO3UNtD_GBBKQ"
                                onChange={captchaValueChanged}
                                onExpired={captchaExpired}
                                grecaptcha={(window as any).grecaptcha}
                            />
                        </Form>
                    )}
                </Formik>
           </div>
       );
    }

    const renderEmailSent = ()=>{
        return (
            <div style={{textAlign:"center"}}>
                <div>
                    Dziękujemy za Waszą wiadomość!<br/>
                    Wkrótce się z Wami skontaktujemy!
                </div>
            </div>
        );
    }

    const renderError = ()=>{
        return (
            <div style={{textAlign:"center"}}>
                Błąd wysyłania wiadomości. Spróbujcie za chwilę albo skontaktujcie się z nami pod adresem <a href="mailto:kontakt@naszslubonline.pl">kontakt@naszslubonline.pl</a>
            </div>
        )
    }

    switch(status){
        case FormState.NewMail: return renderForm();
        case FormState.EmailSent: return renderEmailSent();
        case FormState.ErrorSendingEmail: return renderError();
    }
}

enum FormState{
    NewMail,
    EmailSent,
    ErrorSendingEmail
}