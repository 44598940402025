import React from "react";
import { IOrderOption } from "../Model/IOrderOption";
import { OptionsRepository } from "../Repositories/OptionsRepository";
import { SelectionButton, SelectionButtonProps } from "./SelectionButton";

export interface OptionsDisplayProps{
    options:IOrderOption[],
    onNextClicked?:(selectedOptions:IOrderOption[])=>void
}

export interface OptionsDisplayState{
    selectedOptions:IOrderOption[]
}


export class OptionsDisplay extends React.Component<OptionsDisplayProps, OptionsDisplayState> {
    constructor(props:OptionsDisplayProps) {
        super(props);
        let repository = new OptionsRepository();
        this.state = {
          selectedOptions: repository.getAllOptions().filter(o=>!o.optional)
        };

        this.optionButtonClicked = this.optionButtonClicked.bind(this);
        this.subOptionSelectionChanged = this.subOptionSelectionChanged.bind(this);
        this.orderClicked = this.orderClicked.bind(this);
    };

    render(){
        return (
            <div className="remove-tables">
                <table className="table table-hover table-comparison mb-0" style={{textAlign:"left", tableLayout:"fixed"}}>
                    <tbody>
                        {this.props.options.map(this.optionToControl.bind(this))}
                        <tr>
                            <td></td>
                            <td>Łącznie: </td>
                            <td>{this.sumForOptions()}</td>
                        </tr>
                    </tbody>
                </table>
                <SelectionButton id='order-btn' text='Skontaktujcie się z nami' buttonClicked={this.orderClicked}/>
            </div>
        );
    }

    sumForOptions(){
        let sum = 0;
        for(let i=0; i<this.state.selectedOptions.length;i++){
            sum +=this.state.selectedOptions[i].price;
        }
        return sum;
    }

    optionToControl(option:IOrderOption){

        let propsForButton = (o:IOrderOption):SelectionButtonProps=>{
            let isOptionSelected = this.state.selectedOptions.some(o=>o.id === option.id);
       
            const textForOption = (option:IOrderOption)=>{
                if(option.priceDisplayNameOverride){
                    return option.priceDisplayNameOverride;
                }
                return o.choiceOptions ? `${o.choiceOptions.map(co=>co.price).join('\\')} PLN` : `${o.price} PLN`;
            }

            return {
                id:o.id,
                icon:isOptionSelected ? 'icon-ok' : undefined,
                text:isOptionSelected ? undefined : textForOption(o),
                disabled:!o.optional,
                buttonClicked:this.optionButtonClicked
            };
        }

        return (
            <tr key={`tr-${option.id}`}>
                <td className="nameCell">{option.name}</td>
                <td>{this.subOptionsForControl(option, this.state.selectedOptions.some(o=>o.id === option.id))}</td>
                <td><SelectionButton {...propsForButton(option)}/></td>
            </tr>
        );
    }

    priceForOption(option:IOrderOption){
        if(option.choiceOptions){
            let targetStateOption = this.state.selectedOptions.filter(o=>o.id === option.id)[0];
            if(targetStateOption){
                let selectedSubOption = targetStateOption.choiceOptions ? targetStateOption.choiceOptions.filter(co=>this.state.selectedOptions.some(so=>so.id === co.id))[0] : null;
                if(selectedSubOption){
                    return (<span>{selectedSubOption.price}</span>);
                }
            }
        }
        return (<span>{option.price}</span>)
    }

    subOptionsForControl(option:IOrderOption, visible:boolean){
        let selectedOptionIndex = 0;
        let anySuboptionSelected = this.state.selectedOptions.filter(so=>so.id.indexOf(`${option.id}-`) !== -1)[0];
        if(anySuboptionSelected && option.choiceOptions){
            selectedOptionIndex = option.choiceOptions?.indexOf(anySuboptionSelected);
        }
        if(option.choiceOptions){
            return (
                <select style={{visibility: visible ? "unset" :"hidden"}} key={option.id} onChange={this.subOptionSelectionChanged}>
                    {option.choiceOptions.map((co,i)=>{
                        return (
                            <option key={co.id} value={co.id} selected={i==selectedOptionIndex}>{co.name}</option>
                        );
                    })}
                </select>
            );
        }
        return null;
    }

    subOptionSelectionChanged(ev:any){
        let selectedId = ev.target.selectedOptions[0].value;
        let mainOptionId = selectedId.split('-')[0];
        let mainOption = this.props.options.filter(o=>o.id === mainOptionId)[0];
        let selectedSubOption = mainOption.choiceOptions ? mainOption.choiceOptions.filter(co=>co.id === selectedId)[0] : null;
        if(selectedSubOption){
            this.setState({
                ...this.state,
                selectedOptions:[
                    ...this.state.selectedOptions.filter(o=>!(o.id.indexOf(`${mainOptionId}-`) !== -1)),
                    selectedSubOption
                ]
            });
        }
    }

    optionButtonClicked(id:string){
        let targetElement = this.props.options.filter(o=>o.id == id)[0];
        if(targetElement){
            let currentStateSelected = this.state.selectedOptions.some(o=>o.id === id);
            if(!currentStateSelected){
                try{
                    debugger;
                    window['contactFormOptionSelected'] && window['contactFormOptionSelected'](targetElement);
                }
                catch{}
                this.setState({
                    ...this.state,
                    selectedOptions:targetElement.choiceOptions ?
                    [
                        ...this.state.selectedOptions,
                        targetElement,
                        targetElement.choiceOptions[0]
                    ]
                    :
                    [
                        ...this.state.selectedOptions,
                        targetElement
                    ]
                });
            }
            else{
                try{
                    window['contactFormOptionUnSelected'] && window['contactFormOptionUnSelected'](targetElement);
                }
                catch{}
                this.setState({
                    ...this.state,
                    selectedOptions:this.state.selectedOptions.filter(o=>o.id!==targetElement.id && !(targetElement.choiceOptions && targetElement.choiceOptions.some(teo=>teo.id === o.id)))
                });
            }
        }
    }

    orderClicked(){
        this.props.onNextClicked && this.props.onNextClicked(this.state.selectedOptions);
        window['contactFormOrderButtonClicked'] && window['contactFormOrderButtonClicked']();
    }
}
